import * as React from 'react';
import { I18nNamespaces } from '../i18n';
import { getTranslationsForNamespaces } from '../utils/initializer';
import { GlobalTranslationContext, I18nProvider } from './I18nProvider';

function getLocale(locale: string) {
  if (locale.indexOf('-') > -1) {
    const [language, country] = locale.split('-');
    return {
      language: language,
      country: country,
    };
  } else {
    return {
      language: locale,
      country: undefined, // use default namespaces
    };
  }
}

type PartialNamespaceLabels = {
  [K in keyof I18nNamespaces]?: (keyof I18nNamespaces[K])[];
};

export type I18nServerComponentProps = {
  locale: string;
  namespaces: (keyof I18nNamespaces)[];
  partialNamespaces?: PartialNamespaceLabels;
  context?: Promise<GlobalTranslationContext> | null;
  customer?: string;
};

export async function I18nServerComponent(
  params: I18nServerComponentProps & { children?: React.ReactNode },
) {
  const { language, country } = getLocale(params.locale);

  const [context, translations] = await Promise.all([
    params.context,
    getTranslationsForNamespaces({
      locale: language,
      namespaces: [...params.namespaces],
      country: country,
      partialNamespaces: params.partialNamespaces,
      globalContext: params.context ?? {},
      customer: params.customer,
    }),
  ]);

  return (
    <I18nProvider
      namespaces={translations}
      config={{ languageCode: language, countryCode: country }}
      globalContext={context}
      customer={params.customer}
    >
      {params.children}
    </I18nProvider>
  );
}
