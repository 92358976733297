import { TranslationCore } from '../core';
import { InterpolationContext, LocaleConfig } from '../global';
import { I18nNamespaces, LiteralUnion } from '../i18n';
import { Translate, TranslationContext } from './types';

export function getTranslationContextServer<T extends keyof I18nNamespaces>(
  namespace: T,
  namespaces: I18nNamespaces,
  i18n: LocaleConfig,
  globalContext: InterpolationContext,
): TranslationContext<T> {
  const fn: Translate<T> = (
    key: LiteralUnion<keyof I18nNamespaces[T]>,
    interpolation?: InterpolationContext,
    options?: {
      nullable?: boolean;
    },
  ) => {
    const core = TranslationCore({
      defaultNamespace: namespace,
      locale: i18n,
      namespaces: namespaces,
      globalContext: globalContext,
    });
    return core.t(key as string, interpolation, options);
  };

  return {
    t: fn,
    lang: i18n.languageCode,
    locale: i18n.countryCode ? i18n.languageCode + '-' + i18n.countryCode : i18n.languageCode,
    country: i18n.countryCode,
    currency: i18n.currency,
  };
}
