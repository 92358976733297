import * as React from 'react';
import { VariantProps, cva } from 'class-variance-authority';
import clsx from 'clsx';
import { Slot } from '../utils';

type CvaConfig<T> = Parameters<typeof cva<T>>['1'];

type TextConfigType = {
  variant: {
    h1: string;
    h2: string;
    h3: string;
    h4: string;
    h5: string;
    h6: string;
    paragraph: string;
    large: string;
    default: string;
    small: string;
    xsmall: string;
  };
  tag: {
    h1: string;
    h2: string;
    h3: string;
    h4: string;
    h5: string;
    h6: string;
    label: string;
    p: string;
    span: string;
    div: string;
  };
};

export const Config: CvaConfig<TextConfigType> = {
  variants: {
    variant: {
      h1: 'text-[38px] lg:text-5xl',
      h2: 'text-[32px] lg:text-4xl',
      h3: 'text-[24px] lg:text-3xl',
      h4: 'text-[22px] lg:text-2xl',
      h5: 'text-lg lg:text-xl',
      h6: 'text-base lg:text-base',
      paragraph: 'text-[22px]',
      large: 'text-[18px]',
      default: 'text-base',
      small: 'text-sm',
      xsmall: 'text-xs',
    },
    tag: {
      h1: '',
      h2: '',
      h3: '',
      h4: '',
      h5: '',
      h6: '',
      p: '',
      span: '',
      label: '',
      div: '',
    },
  },
  compoundVariants: [
    {
      variant: ['h1', 'h2', 'h3'],
      class: 'font-alternative',
    },
    {
      tag: ['h1', 'h2', 'h3'],
      class: 'font-alternative',
    },
  ],
  defaultVariants: {
    tag: 'p',
    variant: 'default',
  },
};

const text = cva<TextConfigType>([''], Config);

type TextProps = {
  asChild?: boolean;
  className?: string;
  asHTML?: boolean;
} & VariantProps<typeof text>;

type BaseProps = React.HTMLAttributes<HTMLParagraphElement>;

export const Text = React.forwardRef<HTMLParagraphElement, TextProps & BaseProps>((props, ref) => {
  const { asChild, className, children: _, tag, asHTML, variant, ...rest } = props;

  let content = props.children;

  if (typeof content === 'string' && asHTML) {
    // often the text contains newlines instead of <br /> tags
    const newLineEscapedContent = content?.replace(/\n/g, '<br />');
    rest['dangerouslySetInnerHTML'] = { __html: newLineEscapedContent };
  }

  // default tag - <p />
  let Tag: any = 'p';

  // first precedence: map variant to tag
  if (variant?.startsWith('h')) {
    Tag = variant;
  }

  // second precedence: explicit tag
  if (tag) {
    Tag = tag;
  }

  // third precedence: asChild i.e. assume the immediate child's tag
  if (asChild) {
    Tag = Slot;
  }

  if (typeof content === 'string' && asHTML) {
    return (
      <Tag
        className={clsx(className, text({ variant, tag }))}
        ref={ref}
        {...rest}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    );
  } else {
    return (
      <Tag className={clsx(className, text({ variant, tag }))} ref={ref} {...rest}>
        {content}
      </Tag>
    );
  }
});

Text.displayName = 'Text';
