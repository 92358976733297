import { useTranslationsClient } from '../react/I18nProvider';
import { I18nNamespaces } from './../i18n';
import { getTranslationContextServer } from './utils';

/**
 * Translation hook for React components.
 *
 * Ensure to invoke loadNamespaces in the root component in case of RSC and I18nPageProvider in case of Client side component.
 *
 * Works for both RSC and client components. Based on the location of the caller, for RSC the hook
 * will use global variable to get cached namespaces and for client components uses the context provider.
 *
 * Note* Do not use the hook in client side functions that aren't react component, instead
 * use the `getTranslationContext` function.
 */
export function useTranslation<T extends keyof I18nNamespaces>(ns: T) {
  const isServerOnly = typeof window === 'undefined';
  const useT = isServerOnly
    ? getTranslationContextServer<T>(
        ns,
        globalThis.i18nTranslations.namespaces,
        globalThis.i18nTranslations.locale,
        globalThis.i18nTranslations.globalContext,
      )
    : useTranslationsClient<T>(ns);
  return useT;
}
