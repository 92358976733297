'use client';
import * as React from 'react';
import { TranslationCore } from '../core';
import { InterpolationContext, LocaleConfig } from '../global';
import { I18nNamespaces, LiteralUnion } from '../i18n';
import { Translate, TranslationContext } from '../useTranslation/types';

export type GlobalTranslationContext = InterpolationContext;

type TContext = {
  namespaces: I18nNamespaces;
  setNamespaces: (namespaces: I18nNamespaces) => void;
  config: LocaleConfig;
  globalContext?: GlobalTranslationContext;
  customer?: string;
};

const I18nContext = React.createContext<TContext>({} as TContext);

export function useTranslationsClient<T extends keyof I18nNamespaces>(
  namespace: T,
): TranslationContext<T> {
  const { namespaces, config, globalContext } = React.useContext(I18nContext);
  const i18n = config;

  const fn: Translate<T> = React.useCallback(
    (
      key: LiteralUnion<keyof I18nNamespaces[T]>,
      interpolation?: InterpolationContext,
      options?: {
        nullable?: boolean;
      },
    ) => {
      const core = TranslationCore({
        defaultNamespace: namespace,
        locale: i18n,
        namespaces: namespaces,
        globalContext: globalContext ?? {},
      });
      return core.t(key as string, interpolation, options);
    },
    [namespaces],
  );

  return {
    t: fn,
    lang: i18n.languageCode,
    locale: i18n.countryCode ? i18n.languageCode + '-' + i18n.countryCode : i18n.languageCode,
    country: i18n.countryCode,
    currency: i18n.currency,
  };
}

export function useI18nConfig() {
  const context = React.useContext(I18nContext);
  return {
    config: context.config,
    customer: context.customer,
    globalContext: context.globalContext,
    namespaces: context.namespaces,
    setNamespaces: context.setNamespaces,
  };
}

export function I18nProvider({
  config,
  globalContext,
  namespaces,
  children,
  customer,
}: Omit<TContext, 'setNamespaces'> & { children: React.ReactNode }) {
  const [namespacesState, setNamespacesState] = React.useState<I18nNamespaces>(namespaces);

  const memoized = React.useMemo(() => {
    return {
      config,
      namespaces: namespacesState,
      globalContext,
      customer,
      setNamespaces: (updated: I18nNamespaces) =>
        setNamespacesState((ns) => ({ ...ns, ...updated })),
    };
  }, [config, namespacesState, globalContext, customer, setNamespacesState]);

  React.useEffect(() => {
    if (typeof window != 'undefined') {
      const i18nKeysMap: Record<string, string> = {};
      Object.keys(namespaces).forEach((namespace) => {
        Object.keys((namespaces as any)[namespace]).forEach((key) => {
          i18nKeysMap[`${namespace}:${key}`] = (namespaces as any)[namespace][key];
        });
      });

      (window as any).__i18n_customer = customer;
      (window as any).__i18n_keymap = i18nKeysMap;
      (window as any).__i18n_page_translations = { ...i18nKeysMap };
    }
  }, []);

  return (
    <I18nContext.Provider value={{ ...memoized, namespaces: namespacesState }}>
      {children}
    </I18nContext.Provider>
  );
}
