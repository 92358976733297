import { VERCEL_ENV } from '@dreamplan/envs/public/vercel';
import { InterpolationContext, LocaleConfig } from './global';
import { I18nNamespaces } from './i18n';

function nested(object: InterpolationContext) {
  return {
    get: (path: string) => {
      const pathArray = path.split('.');
      let value: any = object;

      for (const key of pathArray) {
        value = value?.[key];
        if (value == null) {
          return null;
        }
      }

      return value;
    },
  };
}

function formatCurrency(
  value: number | string,
  cultureCode = 'da',
  currency = 'DKK',
  maxFractionDigits = 0,
  minFractionDigits = 0,
) {
  return new Intl.NumberFormat(cultureCode, {
    style: 'currency',
    currency,
    maximumFractionDigits: maxFractionDigits,
    minimumFractionDigits: minFractionDigits,
  }).format(Number(value));
}
const formatters: Record<
  string,
  (props: { value: string | number; config: LocaleConfig; args: string[] }) => string
> = {
  currency: ({ value, config }) =>
    formatCurrency(
      value,
      config.countryCode ? `${config.languageCode}-${config.countryCode}` : config.languageCode,
      config.currency,
    )
      .toString()
      .replace(/\s/g, ' '),
  number: ({ value, config }) => new Intl.NumberFormat(config.languageCode).format(Number(value)),
  fallback: ({ value, args, config }) => {
    const fallbackValue = args.map((arg) => arg.trim().replace(/"/g, '')).join();
    return value?.toString() ?? fallbackValue;
  },
};

export function evaluateTernary(value: string, _context: InterpolationContext) {
  /**
   * Evaluate tenary statements in string with variable context replacement.
   * Expected form of ternary statement is: %var_name=comp_value?true_result:false_result%
   *
   * Unsupported feature:
   *  - other operators like <,>,<=,>=,!=
   *
   * Eg.
   * value: Ok Hvor bor %hasSpouse = 'false' ?du:i%? (with context={hasSpouse: 'false'})
   * return => Ok Hvor bor du?
   */
  const conditionaEvaluator =
    /%(?<var_name>[\w.]+)\s*=\s*(?<comp_value>\w+)\s*\?(?<true_result>.+?)\s*:\s*(?<false_result>.+?)%/g;
  const context = nested(_context);

  const result = value.replace(
    conditionaEvaluator,
    (match, varName, compValue, trueResult, falseResult) => {
      const varValue = context.get(varName);
      if (varName && compValue && trueResult && falseResult) {
        const output = varValue === compValue ? trueResult : falseResult;
        return output;
      }
      return '';
    },
  );

  return result;
}

function interpolation(
  rawText: string,
  values: InterpolationContext,
  globalContext: InterpolationContext,
  config: LocaleConfig,
) {
  /**
   * The template string can be in form of `{{<variable_name>, <function_name>, <arguments_to_function>}}` where format is optional
   */
  const regex = /{{\s*([^{}]+)\s*}}/gi;
  const context = nested({ context: globalContext, ...values });

  const interpolatedString = rawText.replace(regex, (_match, interpolate) => {
    const [key, fn, ...args] = interpolate.split(',').map((arg: string) => arg.trim()) as string[];
    const isFallbackAvailable = fn?.match(/["'].+["']/) != null;

    if (fn == null || isFallbackAvailable) {
      const interpolate = context.get(key);
      if (interpolate == null) {
        if (!isFallbackAvailable) {
          console.error(`Missing interpolation value for key ${key}`);
        }
        return isFallbackAvailable ? fn.replace(/["']/g, '') : _match;
      } else {
        return interpolate as string;
      }
    } else {
      const formatter = formatters[fn];
      if (formatter == null) {
        console.error(`Missing formatter for ${fn}`);
        return interpolate;
      } else {
        const interpolate = context.get(key);
        if (interpolate == null) {
          throw Error(`Missing interpolation value for key ${key}`);
        } else {
          return formatter({
            args: args.map((arg: string) => arg.trim()),
            value: interpolate,
            config,
          });
        }
      }
    }
  });

  const ternaryEvaluation = evaluateTernary(interpolatedString, values);

  return ternaryEvaluation;
}

function extractPluralKey(
  getter: (path: string) => string | null,
  key: string,
  count: number,
  locale: string,
) {
  const pluralization = new Intl.PluralRules(locale);
  const pluralForm = pluralization.select(count);

  const exactCountKey = `${key}_${count}`;
  const pluralKey = `${key}_${pluralForm}`;

  if (getter(exactCountKey) != null) {
    return exactCountKey;
  } else if (getter(pluralKey) != null) {
    return pluralKey;
  } else {
    return null;
  }
}

function extractPossibleNamespace(key: string, defaultNamespace: string) {
  if (key?.includes?.(':')) {
    return {
      namespace: key.split(':')[0],
      key: key.split(':')[1],
    };
  } else {
    return {
      namespace: defaultNamespace,
      key,
    };
  }
}

export function TranslationCore<T extends keyof I18nNamespaces>({
  defaultNamespace,
  locale: localeConfig,
  namespaces,
  globalContext,
}: {
  defaultNamespace: keyof I18nNamespaces;
  namespaces: I18nNamespaces;
  locale: LocaleConfig;
  globalContext: InterpolationContext;
}) {
  const t = (
    key: string,
    interpolationValue: InterpolationContext | null = null,
    options?: {
      nullable?: boolean;
    },
  ) => {
    if (!key) {
      return null;
    }

    // key could be in form of 'ns:key' eg. "common:hello"
    let { key: tKey, namespace: tNSKey } = extractPossibleNamespace(
      key as string,
      defaultNamespace,
    );
    const nsDictionary = nested(namespaces[tNSKey as T]);

    // attempt to check if plural form exists
    if (interpolationValue?.['count'] != null) {
      const pluralCount = Number(interpolationValue.count);
      const possiblePluralKey = extractPluralKey(
        nsDictionary.get,
        tKey,
        pluralCount,
        localeConfig.languageCode,
      );
      // update the translation key look up with plural key
      if (possiblePluralKey) tKey = possiblePluralKey;
    }

    // interpolate values with formatting
    const rawText = nsDictionary.get(tKey);

    const addNewTranslationToKeyMap = (text: string) => {
      if (typeof window !== 'undefined' && VERCEL_ENV !== 'production') {
        const win = window as any;
        // mutable global keymap
        win.__i18n_keymap = win.__i18n_keymap ?? {};
        win.__i18n_page_translations = win.__i18n_page_translations ?? {};

        if (win.__i18n_keymap[`${tNSKey}:${key}`] == null) {
          win.__i18n_keymap[`${tNSKey}:${key}`] = text;
          win.__i18n_page_translations[`${tNSKey}:${key}`] = 'New translation';
        }
      }
    };

    if (rawText == null) {
      if (options?.nullable) return null;
      const result = `❓❓${tNSKey}:${key}❓❓`;
      addNewTranslationToKeyMap(result);

      return result;
    }

    const result = interpolation(
      rawText,
      interpolationValue ?? {},
      globalContext,
      localeConfig,
    ) as any;

    return result;
  };

  return { t };
}
